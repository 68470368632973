import React from 'react'

import TermsCondition from '../../components/mainComponents/TermsConditions/TermsConditions'

const TermsConditions = () => (
  <>
    <TermsCondition />
  </>
)

export default React.memo(TermsConditions)
