import React from 'react'
import { Link } from 'gatsby'

import * as styles from './styles.module.scss'

const TermsCondition = () => (
  <div>
    <div className={styles.headerContainer}>
      <h1 className={styles.headerContainerH1}>Terms & conditions</h1>
    </div>
    <div className={styles.headerContainerBloks}>
      <div className={styles.headerContainerBloksLinks}>
        <Link
          to="/terms-and-conditions/privacy-policy"
          className={styles.headerContainerBloksLinksTo}
          activeClassName={styles.headerContainerBloksLinksToActive}
        >
          privacy policy
        </Link>
        <Link
          to="/terms-and-conditions/cookies"
          className={styles.headerContainerBloksLinksTo}
          activeClassName={styles.headerContainerBloksLinksToActive}
        >
          cookies
        </Link>
      </div>
    </div>
  </div>
)

export default React.memo(TermsCondition)
